import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateBundle } from '../../api'
import {hasValue} from '../../../../../utils/string'
import {toHours} from '../../../../../domain/subscriptionPeroid'

import TextInput from '../../../../../Component/TextInput'

export const Traffic =  ({values}) => {
    const { t } = useTranslation('bundle');
    var val = values["traffic"];
   
    return <>
    <Grid item sm={ 6 } xs={6}>
        <FormControl fullWidth>
            <Field label={t('traffic.title')}
                   name={"traffic"}
                   component={TextInput}
                   number

                   adornment ={val!==""?t("gb"): t("unlimited")}
                   />
        </FormControl>
    </Grid>
    </>
}
export const validate = (values, errors, t)=> {
    const field= "traffic";
    const traffic = parseInt(values[field])
    if (traffic <= 0) {
        errors[field] = t('traffic.required');
    }
    console.log('traffic');

    if(values["isResellerBundle"] && !traffic) {
        errors[field] = t('error.unlimitedTrafficNotAvailableForReseller');
    } else
    if(values["isResellerBundle"] && traffic<100) {
        errors[field] = t('error.lessThan100GBNotAvailableForReseller');
    }
    // toHours(values.durationValue, values.durationUnit)

    const months = Math.round(toHours(values.durationValue, values.durationUnit)) / 730

    if ((traffic/months) < 49) {
        errors[field] = t('bundle:error.lessThan50GBNotAvailable');
    }
    
    
    // else
    // if(parseInt(values["concurrencyLimit"]) !== 1 && !traffic) {
    //     errors[field] = t('error.unlimitedTrafficAvailableForSingleUser');
    // }
    return errors;
}



class View extends React.Component {

    update = async (values) =>{
        const {id, traffic }=values;
        return await updateBundle({id, traffic:traffic* 1024 });
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh,readonly, t } = this.props;
        const { traffic } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('traffic.title')}
                          headline={ traffic + 'GB' }
                          update={this.update}
                          validate={validate}
                          values={values}
                          onRefresh={onRefresh}
                          readonly = {readonly}
                          editComponent={ Traffic } />
    }
}


export const TrafficView = withTranslation('bundle')(View);