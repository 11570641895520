import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateSubscription } from '../../api'
import {hasValue} from '../../../../../utils/string'
import {toHours} from '../../../../../domain/subscriptionPeroid'

import TextInput from '../../../../../Component/TextInput'

export const AvailableTraffic =  ({values}) => {
    const { t } = useTranslation('bundle');
    var val = values["activeBundle.traffic"];
    // alert(val);
    return <>
    <Grid item sm={ 3 } xs={12}>
        <FormControl fullWidth>
            <Field label={t('availableTraffic.title')}
                   name={"activeBundle.traffic"}
                   component={TextInput}
                   number

                   adornment ={val!==""?t("gb"):t("unlimited")}
                   />
        </FormControl>
    </Grid>
    </>
}
export const validate = (values, errors, t)=> {
    // const field= "activeBundle.traffic";
    // const value = values[field]
    // if ( parseInt(value)<=0) {
    //     errors[field] = t('availableTraffic.required');
    // }


    const field= "activeBundle.traffic";
    const traffic = parseInt(values[field])
   

    if (traffic <= 0) {
        errors[field] = t('bundle:traffic.required');
    }
    console.log('traffic');
    console.log(values["activeBundle.concurrencyLimit"]);
    if(values["isReseller"] && !traffic) {
        errors[field] = t('bundle:error.unlimitedTrafficNotAvailableForReseller');
    } else
    if(values["isReseller"] && traffic<100) {
        errors[field] = t('bundle:error.lessThan100GBNotAvailableForReseller');
    } 
    
    const months = Math.round(toHours(values.durationValue, values.durationUnit)) / 730

    if ((traffic/months) < 49) {
        errors[field] = t('bundle:error.lessThan50GBNotAvailable');
    }
    // else
    // if(parseInt(values["activeBundle.concurrencyLimit"]) !== 1 && !traffic) {
    //     errors[field] = t('bundle:error.unlimitedTrafficAvailableForSingleUser');
    // }


    return errors;
}


class View extends React.Component {

    update = async (values) =>{
        const {id, availableTraffic }=values;
        return await updateSubscription({id, availableTraffic });
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh,readonly, t } = this.props;
        const { availableTraffic } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('availableTraffic.title')}
                          headline={ availableTraffic }
                          update={this.update}
                          validate={validate}
                          values={values}
                          onRefresh={onRefresh}
                          readonly = {readonly}
                          editComponent={ AvailableTraffic } />
    }
}


export const AvailableTrafficView = withTranslation('bundle')(View);